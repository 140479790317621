export const AuthorizationTypes = t => {
    return [{
        Id: 1,
        Name: t("#_authorizationtypes_1"),
        Value: -1
    },
    {
        Id: 2,
        Name: t("#_authorizationtypes_2"),
        Value: 0
    },
    {
        Id: 3,
        Name: t("#_authorizationtypes_3"),
        Value: 1
    },
    {
        Id: 4,
        Name: t("#_authorizationtypes_4"),
        Value: 2
    },
    {
        Id: 5,
        Name: t("#_authorizationtypes_9"),
        Value: 3
    }]
}

export const ActionTypes = t => {
    return [
    {
        Id: 0,
        Name: t("authorization_action_type_0"),
    },
    {
        Id: 1,
        Name: t("authorization_action_type_1"),
    },
    {
        Id: 2,
        Name: t("authorization_action_type_2"),
    }]
}

export const getAuthorizationTypeNameByValue = (value, t) => {
    switch (value) {
        case 0:
            return t("#_authorizationtypes_5");
        case 1:
            return t("#_authorizationtypes_6")
        case 2:
            return t("#_authorizationtypes_7")
        case 3:
            return t("#_authorizationtypes_9")
        default:
            return t("#_authorizationtypes_8")
    }
}
